<template>
  <div>
    <!-- job stats -->
    <v-row class="stats" v-if="jobItems.length > 0 && $route.name === 'jobs'">
      <v-col cols="12">
        <JobStats />
      </v-col>
    </v-row>
    <!-- success alert -->
    <v-row v-if="showAlert">
      <v-col cols="4" />
      <v-col cols="4">
        <SuccessAlert
          data-cy="alert"
          v-bind:type="alertType"
          v-bind:message="alertMessage"
        />
      </v-col>
      <v-col cols="4"> </v-col>
    </v-row>

    <!-- jobs table -->
    <v-data-table
      v-else
      v-model="selected"
      dense
      :search="search"
      class="px-6 mt-5"
      loading-text="loading..."
      :loading="loading"
      no-data-text="No Jobs available"
      :headers="headers"
      :items="jobItems"
      :items-per-page="10"
      show-select
      item-key="_uuid"
      :sort-by="['createdDate']"
      :sort-desc="[true]"
      single-select
    >
      <!-- table search function -->
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <!-- date conversion -->
      <template v-slot:[`item.createdDate`]="{ item }">
        <span>{{ new Date(item.createdDate).toLocaleString() }}</span>
      </template>
      <!-- job state symbols -->
      <template v-slot:[`item.state`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon v-if="item.state === 'ACTIVE'" color="#427cbe"
                >mdi-run</v-icon
              >
              <v-icon v-if="item.state === 'COMPLETED'" color="#40aa53"
                >mdi-flag-checkered</v-icon
              >
              <v-icon
                v-if="item.state === 'EXTERNALLY_TERMINATED'"
                color="#848484"
                >mdi-close-circle-outline</v-icon
              >
              <v-icon v-if="item.state === 'FAILED'" color="#f34a6a"
                >mdi-alert-outline</v-icon
              >
            </span>
          </template>
          <span>{{ item.state }}</span>
        </v-tooltip>
      </template>
      <!-- table actions -->
      <template v-slot:item.actions="{ item }">
        <!-- result page button -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              data-cy="show_results"
              :disabled="item.state !== 'COMPLETED'"
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
              x-small
              @click.native="transferJobTableItem(item)"
              :to="{ name: 'results' }"
            >
              <v-icon>mdi-chart-areaspline</v-icon>
            </v-btn>
          </template>
          <span>Show Results</span>
        </v-tooltip>
        <!-- copy to clipboard -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              data-cy="copy"
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
              @click="copyToClipboard(item)"
              x-small
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Copy Job Configuration to Clipboard</span>
        </v-tooltip>
        <!-- info box button -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              data-cy="show_info"
              @click="info(item)"
              v-bind="attrs"
              v-on="on"
              icon
              x-small
              color="primary"
            >
              <v-icon>mdi-clipboard-text-outline</v-icon></v-btn
            >
          </template>
          <span>Show Job Configuration & Info</span>
        </v-tooltip>
        <!-- edit job structure -> at the moment only working for one structure! -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="item.structures.length === 0"
              data-cy="load_to_ui"
              color="primary"
              @click="loadToUI(item)"
              v-bind="attrs"
              v-on="on"
              icon
              x-small
            >
              <v-icon>mdi-weather-cloudy-arrow-right</v-icon></v-btn
            >
          </template>
          <span>Edit Structure</span>
        </v-tooltip>
        <!-- cancel running job -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="item.state !== 'ACTIVE'"
              data-cy="cancel_job"
              color="primary"
              @click="cancel(item._uuid)"
              v-bind="attrs"
              v-on="on"
              icon
              x-small
            >
              <v-icon>mdi-cancel</v-icon></v-btn
            >
          </template>
          <span>Cancel running job</span>
        </v-tooltip>
        <!-- delete item -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              data-cy="delete"
              color="primary"
              v-bind="attrs"
              v-on="on"
              icon
              x-small
              @click="askConfirmation(item)"
            >
              <v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <!-- foldable description -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">Description: {{ item.description }}</td>
      </template>

      <!-- select job checkbox -->
      <template v-slot:item.data-table-select="{ item }">
        <v-radio-group v-model="radios">
          <v-radio :value="item" @click="prepareJobInfo(item)"></v-radio>
        </v-radio-group>
      </template>
    </v-data-table>

    <ConfigurationInfo
      data-cy="job_info"
      ref="info"
      :job="job"
      :structures="structures"
      :jobParam="jobParam"
      :hasTabs="hasTabs"
      :copyData="copyData"
    />
    <ConfirmDialog
      :visible="showConfirmDialog"
      @close="showConfirmDialog = false"
      @remove="deleteJob"
    />
  </div>
</template>

<script>
const ConfigurationInfo = () => import('../general/ConfigurationInfo.vue')
const ConfirmDialog = () => import('../general/ConfirmDialog.vue')
const SuccessAlert = () => import('../general/SuccessAlert.vue')

import utils from '../../appUtils/utils'

import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  components: {
    ConfigurationInfo,
    ConfirmDialog,
    SuccessAlert,
  },

  data() {
    return {
      /* data-table */
      radios: 0,
      selected: [],
      search: '',
      loading: false,
      singleExpand: false,
      expanded: [],
      /* table actions */
      results: [],
      itemToDelete: {},
      showConfirmDialog: false,
      hasTabs: true,
      copyData: {},
      job: {},
      jobParam: {},
      structures: [],
      /* SuccessAlert component */
      showAlert: false,
      alertMessage: '',
      alertType: '',
      /* general */
      fetchingData: false,
    }
  },

  props: { loadTable: Boolean },

  methods: {
    ...mapActions('backend', ['load', 'loadById', 'delete', 'cancelProcess']),

    ...mapMutations('dynamicForms', {
      createUserInputFromDataNode: 'createUserInputFromDataNode',
      setDynamicForms: 'set',
    }),

    ...mapMutations('backend', ['setCurrentProcesses', 'set', 'setJobTableItem']),

    prepareJobInfo(item) {
      this.set(['process', item])
    },

    cancel(_uuid) {
      this.cancelProcess(_uuid)
      this.load(['process'])
    },

    /* table actions */
    async transferJobTableItem(item) {
      await this.setJobTableItem(item)
      this.$router.push({ name: 'results' })
    },

    copyToClipboard(item) {
      this.showAlert = false
      this.copyData = cloneDeep(this.getById(['process', item._uuid]))
      try {
        navigator.clipboard.writeText(JSON.stringify(this.copyData, null, 2))
        this.prepareAlert('success', 'Copied successfully.')
      } catch (error) {
        this.prepareAlert('error', 'Copying failed.')
      }
    },

    async info(item) {
      this.job = await this.loadById(['process', item._uuid])
      let inputIDs = []
      for (let value of Object.values(this.job.inputs)) {
        inputIDs.push(value)
      }
      this.structures = this.getDatasByTag('structure').filter((e) =>
        inputIDs.includes(e._uuid)
      )
      this.jobParam = this.getDatasByTag('job-params').find((e) =>
        inputIDs.includes(e._uuid)
      )
      this.$refs.info.openDialog()
    },

    async loadToUI(item) {
      // todo: for more than one structure convert item.structures to an array
      this.loading = true
      let data = this.datas.find((e) => e.label === item.structures)
      let structure = await this.loadById(['data', data._uuid])
      await this.createUserInputFromDataNode(structure)
      this.setDynamicForms(['edit', true])
      let routeName = structure.hqschema.label
      await this.$router.push({ name: routeName })
      this.loading = false
    },

    askConfirmation(item) {
      if (item !== '') {
        this.itemToDelete = item
      }
      this.showConfirmDialog = true
    },

    async deleteJob() {
      this.loading = true
      try {
        await this.delete(['process', this.itemToDelete._uuid])
        this.prepareAlert('success', 'Deleted successfully.')
      } catch (error) {
        this.prepareAlert('error', 'Your job could not be deleted.')
      }
      await this.load(['process'])
      this.setCurrentProcesses()
      this.loading = false
      this.showConfirmDialog = false
    },

    /* general */
    prepareAlert(type, message) {
      this.alertType = type
      this.alertMessage = message
      this.showAlert = true
      if (type === 'success') {
        window.setTimeout(() => {
          this.showAlert = false
        }, this.alertTimeout)
      }
    },

    async loadScopeData() {
      await this.load(['process'])
      if (this.schemas.length === 0) {
        await this.load(['schema'])
      }
      if (this.workflows.length === 0) {
        await this.load(['workflow'])
      }
      if (this.datas.length === 0) {
        this.load(['data'])
      }
    },
  },

  computed: {
    ...mapGetters('backend', [
      'getById',
      'getJobTable',
      'getByLabel',
      'getDatasByTag',
    ]),

    ...mapState('backend', {
      datas: (state) => state.datas,
      schemas: (state) => state.schemas,
      currentWorkflows: (state) => state.currentWorkflows,
      workflows: (state) => state.workflows,
      alertTimeout: (state) => state.alertTimeout,
    }),

    ...mapState('dynamicForms', {
      currentStructureCard: (state) => state.currentStructureCard,
    }),

    /* data-table */
    jobItems() {
      let needsAllJobs = this.$route.name === 'jobs' ? true : false
      return utils.transformJobTableToFormattedStrings(
        this.getJobTable(needsAllJobs)
      )
    },

    headers() {
      return [
        { text: 'Label', align: 'start', value: 'label', sortable: true },
        { text: 'Structures', value: 'structures', sortable: false },
        { text: 'Created Date', value: 'createdDate' },
        { text: 'State', value: 'state', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false, align: 'end' },
      ]
    },
  },

  watch: {
    async loadTable() {
      await this.setCurrentProcesses()
      await this.getJobTable(false)
    },
  },

  async created() {
    this.fetchingData = true
    this.loadScopeData()
    this.fetchingData = false
  },
}
</script>

<style scoped></style>
